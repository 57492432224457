import React from 'react'
import styled from 'styled-components'
import { Widget, HtmlWidgetType } from 'src/interfaces'
import ErrorWidget from 'src/components/UI/ErrorWidget'
import { useWindow } from 'src/context/WindowContext'

type DashboardItemProps = {
  widget: Widget
}

type IframeProps = {
  isMouseDown?: boolean
}

const Iframe = styled.iframe<IframeProps>`
  width: 100%;
  height: 100%;
  border: none;
  pointer-events: ${(props) => (props.isMouseDown ? 'none' : 'auto')};

  body {
    margin: 0;
  }
`

const HtmlWidget = ({ widget }: DashboardItemProps) => {
  const { url, html } = widget as HtmlWidgetType
  const { isMouseDown } = useWindow()
  return (
    <>
      {!url && !html && <ErrorWidget />}
      {url && <Iframe isMouseDown={isMouseDown} src={url} />}
      {html && (
        <Iframe
          isMouseDown={isMouseDown}
          srcDoc={`<style>body { margin: 0; height: 100%; } html { height: 100%; }</style>${html}`}
        />
      )}
    </>
  )
}

export default HtmlWidget
