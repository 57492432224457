import React from 'react'
import styled from 'styled-components'

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ErrorWidget = () => {
  return <ErrorWrapper>Invalid widget value</ErrorWrapper>
}

export default ErrorWidget
