import React from 'react'
import { MenuOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { FullScreenHandle } from 'react-full-screen'

import LogoBanner from './LogoBanner'
import RightCorner from './RightCorner'

const Wrapper = styled.div`
  height: 48px;
  padding: 6px 16px;
  z-index: 5;
  background: ${(props) => props.theme.palette.white};
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
  display: flex;
  justify-content: space-between;
  vertical-align: center;
  align-items: center;
`

const IconWrapper = styled.div`
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.palette.pink};
  }
`

type HeaderProps = {
  onMenuClick: () => void
  fullScreenHandle: FullScreenHandle
}

const Header = ({ fullScreenHandle, onMenuClick }: HeaderProps) => {
  return (
    <Wrapper>
      <IconWrapper onClick={onMenuClick}>
        <MenuOutlined />
      </IconWrapper>
      <LogoBanner />
      <RightCorner fullScreenHandle={fullScreenHandle} />
    </Wrapper>
  )
}

export default Header
