import { useCallback, useState } from 'react'
import * as R from 'ramda'
import RGL from 'react-grid-layout'
import firebase, { getUser, setDashboardWidget } from 'src/utils/firebase'
import { Dashboard, Widget } from 'src/interfaces'
import { useDashboard } from 'src/context/DashboardContext'
import { layoutTransformForFirebase } from 'src/utils'

type UseUpdateWidget = {
  isLoading: boolean
  error: Error | null
  removeWidget: (boardIndex: number, widgetId: string) => void
  addWidget: (boardIndex: number, widget: Widget) => void
  updateWidget: (widgetId: string, widget: Widget) => void
}

const useUpdateWidget = (): UseUpdateWidget => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const { currentIndex, dashboards, setDashboards } = useDashboard()

  // Widget
  const removeWidget = useCallback(
    async (boardIndex: number, widgetId: string) => {
      setError(null)
      setIsLoading(true)
      const { widgets, layout, ...dashboard } = dashboards[boardIndex]
      const keysToKeep = R.filter((key) => key !== widgetId)(R.keys(widgets))
      const updatedLayout = R.remove<RGL.Layout>(
        R.findIndex(R.propEq('i', widgetId))(layout),
        1
      )(layout)

      const updatedDashboard = {
        ...dashboard,
        widgets: R.pick(keysToKeep)(widgets),
        layout: updatedLayout,
      }

      R.compose(
        setDashboards,
        R.assocPath<Dashboard, Dashboard[]>([boardIndex], updatedDashboard),
        R.clone
      )(dashboards)

      try {
        const uid: string | null = R.propOr(null, 'uid')(getUser())
        if (uid) {
          const updates: Record<string, any> = {}
          updates[`widgets/${widgetId}`] = {}
          updates['layout'] = layoutTransformForFirebase(updatedLayout)
          await firebase.database().ref(`/Users/${uid}/dashboards/${boardIndex}`).update(updates)
        }
      } catch (error) {
        setError(error)
      }
      setIsLoading(false)
    },
    [dashboards, setDashboards]
  )

  const addWidget = useCallback(
    async (boardIndex: number, widget: Widget) => {
      const { widgets, layout, ...dashboard } = dashboards[boardIndex]
      const newLayout: RGL.Layout = {
        i: R.prop('id')(widget),
        x: (R.pathOr(1, [boardIndex, 'layout', 'length'])(dashboards) * 6) % 12,
        y: Infinity,
        w: 6,
        h: 2,
        minW: 1,
        minH: 1,
      }

      const newLayouts = R.append(newLayout)(layout)

      const updatedDashboard = {
        ...dashboard,
        widgets: R.assoc(R.prop('id')(widget), widget)(widgets),
        layout: newLayouts,
      }

      R.compose(
        setDashboards,
        R.assocPath<Dashboard, Dashboard[]>([boardIndex], updatedDashboard),
        R.clone
      )(dashboards)

      try {
        const uid: string | null = R.propOr(null, 'uid')(getUser())
        if (uid) {
          const updates: Record<string, any> = {}
          updates[`/Users/${uid}/dashboards/${boardIndex}/widgets/${R.prop('id')(widget)}`] = widget
          updates[`/Users/${uid}/dashboards/${boardIndex}/layout`] = layoutTransformForFirebase(
            newLayouts
          )
          await firebase.database().ref().update(updates)
        }
      } catch (error) {
        setError(error)
      }
    },
    [dashboards, setDashboards]
  )

  const updateWidget = useCallback(
    async (widgetId: string, widget: Widget) => {
      R.compose(
        setDashboards,
        R.assocPath<Widget, Dashboard[]>([currentIndex, 'widgets', widgetId], widget),
        R.clone
      )(dashboards)

      try {
        const uid: string | null = R.propOr(null, 'uid')(getUser())
        if (uid) {
          await firebase
            .database()
            .ref(`/Users/${uid}/dashboards/${currentIndex}/widgets/${R.prop('id')(widget)}`)
            .set(widget)
        }
      } catch (error) {
        setError(error)
      }
    },
    [currentIndex, dashboards, setDashboards]
  )

  return { isLoading, error, addWidget, updateWidget, removeWidget }
}

export default useUpdateWidget
