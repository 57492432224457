// import 'antd/dist/antd.less'
import './App.less'

import React from 'react'
import { Route, Switch } from 'react-router'
import { ThemeProvider } from 'styled-components'

import BlankLayout from 'src/components/layouts/BlankLayout'
import MainLayout from 'src/components/layouts/MainLayout'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { useFirebaseAuth } from 'src/hooks/auth'
import { ContextProvider } from 'src/context'
import theme from 'src/theme'
import { publicRoutes, restrictedRoutes } from './routes'

const App = () => {
  const { isInitialized, isLoggedIn } = useFirebaseAuth()

  if (!isInitialized) {
    return <LoadingSpinner />
  }
  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <MainLayout>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Switch>
        </MainLayout>
      </ContextProvider>
      {/* {isLoggedIn ? (
        <MainLayout>
          <Switch>
            {restrictedRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Switch>
        </MainLayout>
      ) : (
        <BlankLayout>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Switch>
        </BlankLayout>
      )} */}
    </ThemeProvider>
  )
}
export default App
