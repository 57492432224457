import axios from 'axios'
import qs from 'qs'

const CrytoCompareAxios = axios.create({
  baseURL: process.env.REACT_APP_CRYPTO_COMPARE_API_URL,
  timeout: 10000,
  paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets' }),
})
const EtherscanAxios = axios.create({
  baseURL: process.env.REACT_APP_ETHERSCAN_API_URL,
  timeout: 10000,
  paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'brackets' }),
})

// instance.interceptors.response.use(
//   (response: any) => response,
//   (e: Error) => {
//     const responseError = R.path(['response', 'data'], e)
//     throw responseError
//   }
// )

export { CrytoCompareAxios, EtherscanAxios }
