import { ToolOutlined, DragOutlined, DeleteOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
import * as R from 'ramda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useDashboard } from 'src/context/DashboardContext'
import useUpdateWidget from 'src/hooks/dashboard/useUpdateWidget'
import Modal from 'src/components/UI/Modal'
import WidgetForm from 'src/components/WidgetForm'
import { Widget, WidgetType } from 'src/interfaces'

export const WidgetToolsWrapper = styled.div`
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -26px;
  background-color: ${(props) => props.theme.palette.white};
  /* margin: -1px -21px 0 0; */
  box-shadow: 0 2px 4px rgba(0, 21, 41, 0.08);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: white;
  /* width: 28px; */
  z-index: 10;
  padding: 2px;

  span {
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin: 2px;
      transition: opacity 0.2s ease-in-out;
      color: ${(props) => props.theme.palette.darkGrey};
      fill: ${(props) => props.theme.palette.darkGrey};
    }
  }
  .react-draggable-handle {
    svg {
      cursor: move;
    }
  }
`

const StyledDivider = styled(Divider)`
  margin: 0 4px;
`

type DashboardItemProps = {
  widget: Widget
}

const WidgetTools = ({ widget }: DashboardItemProps) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const { currentIndex } = useDashboard()
  const { removeWidget, updateWidget } = useUpdateWidget()
  const widgetId = useMemo(() => R.prop('id')(widget), [widget])
  const type = useMemo(() => R.prop('type')(widget), [widget])

  const handleWidgetUpdate = useCallback(
    (updatedWidget: any) => {
      updateWidget(widgetId, { ...widget, ...updatedWidget })
      setShowEditModal(false)
    },
    [updateWidget, widget, widgetId]
  )

  const showEditButton = useMemo(() => type !== WidgetType.CRYPTO_GAS_PRICE, [type])

  return (
    <WidgetToolsWrapper>
      <DragOutlined className="react-draggable-handle" />
      {showEditButton && <ToolOutlined onClick={() => setShowEditModal(true)} />}
      <StyledDivider type="vertical" />
      <DeleteOutlined
        onClick={() => {
          removeWidget(currentIndex, widgetId)
        }}
      />
      {showEditModal && (
        <Modal visible={showEditModal} onCancel={() => setShowEditModal(false)} footer={null}>
          <WidgetForm type={type} widget={widget} onSubmit={handleWidgetUpdate} />
        </Modal>
      )}
    </WidgetToolsWrapper>
  )
}

export default WidgetTools
