const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },

  transitions: {
    basic: 'all 0.3s, padding 0s',
  },

  palette: {
    primary: '#ff9f00',
    secondary: '#0033e7',

    background: '#ffffff',
    error: '#ff495c',

    lightPlaceholder: '#ffffff7f',
    darkPlaceholder: '#0000004d',

    divider: '#00000026',

    white: '#ffffff',
    black: '#000000',
    darkGrey: '#929292',

    // From color wheel
    pink: '#D996C7',
    lightPink: '#D9A0BF',
    blue: '#049DD9',
    lightBlue: '#14B5D9',
    grey: '#f2f2f2',

    // For Gas Tracker
    green: '#00c9a7',
    red: '#B71A1A',

    normalText: '#262626',

    hoverOverlay: '#00000026',

    footerBackground: '#4a4a4a',
    headerDivider: '#00000033',

    updateAppLogoBackground: '#f5f6fa',
    imagePlaceholderBackground: '#f2f2f2',
  },
}

export default theme
