import { useCallback, useState } from 'react'

import { getGasOracle } from 'src/utils/api'

type UseGetGasPrice = [
  {
    prices?: {
      fast: string
      propose: string
      safe: string
      base: string
    }
    isLoading: boolean
    error?: Error
  },
  () => void
]

export const useGetGasPrice = (): UseGetGasPrice => {
  const [isLoading, setLoading] = useState(false)
  const [prices, setPrices] = useState({
    fast: '0',
    propose: '0',
    safe: '0',
    base: '0',
  })
  const [error, setError] = useState<Error>()

  const getGasPrice = useCallback(async () => {
    setLoading(true)
    try {
      const {
        data: { result },
      } = await getGasOracle()
      const { FastGasPrice, ProposeGasPrice, SafeGasPrice, suggestBaseFee } = result
      console.log(result)
      setPrices({
        fast: FastGasPrice,
        propose: ProposeGasPrice,
        safe: SafeGasPrice,
        base: Number(suggestBaseFee).toFixed(2),
      })
    } catch (error) {
      setError(error as Error)
    }
    setLoading(false)
  }, [])

  return [{ error, prices, isLoading }, getGasPrice]
}
