import { Row, Col } from 'antd'
import styled from 'styled-components'

const WidgetsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  height: 100%;
`
const WidgetsRow = styled(Row)`
  width: 100%;
  margin: 0 8px !important;
  svg {
    width: 30px;
    height: 30px;
    margin-bottom: 12px;
  }
  div {
    text-align: center;
    font-size: 12px;
  }
`
const WidgetFormWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const WidgetFormCol = styled.div`
  width: calc(50% - 12px);
  overflow: auto;
  :first-child {
    margin-right: 12px;
  }
  :last-child {
    margin-left: 12px;
    border: 1px solid ${(props) => props.theme.palette.grey};
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.palette.grey};
  border-radius: 6px;
  height: 140px;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;

  :before {
    /* Position the pseudo-element. */
    content: ' ';
    position: absolute;
    margin: 8px;
    border-radius: 6px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* Create the box shadow at expanded size. */
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);

    /* Hidden by default. */
    opacity: 0;
    transition: opacity 500ms;
  }

  :hover::before {
    opacity: 1;
  }
`

const BackWrapper = styled.div`
  cursor: pointer;
  display: inline;
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.blue};
`

const Title = styled.span`
  font-size: 22px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.blue};
`

const StyledCol = styled(Col)`
  height: max-content;
`

export {
  WidgetsWrapper,
  WidgetFormWrapper,
  WidgetFormCol,
  WidgetsRow,
  Title,
  BackWrapper,
  Item,
  StyledCol,
}
