import React from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Widget, WidgetType } from 'src/interfaces'
import HtmlWidget from './HtmlWidget'
import GasFeeSummaryWidget from './GasFeeSummaryWidget'
import GasPriceWidget from './GasPriceWidget'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

type DashboardItemProps = {
  // children?: React.ReactElement
  widget?: Widget
}

const WidgetItem = ({ widget }: DashboardItemProps) => {
  if (!widget) {
    return null
  }
  const type = R.prop('type')(widget)

  return (
    <Wrapper>
      {type === WidgetType.HTML && <HtmlWidget widget={widget} />}
      {type === WidgetType.CRYPTO_GAS_SUMMARY && <GasFeeSummaryWidget widget={widget} />}
      {type === WidgetType.CRYPTO_GAS_PRICE && <GasPriceWidget />}
    </Wrapper>
  )
}

export default WidgetItem
