import { Menu, Input } from 'antd'
import * as R from 'ramda'
import React, { useState } from 'react'
import { ClearOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Dropdown from 'src/components/UI/Dropdown'
import { formatRoute, isNilOrEmpty } from 'src/utils'
import { useDashboard } from 'src/context/DashboardContext'
import useUpdateDashboard from 'src/hooks/dashboard/useUpdateDashboard'
import { Dashboard } from 'src/interfaces'
import { ReactComponent as PlusIcon } from 'src/assets/add.svg'
import { useHistory } from 'react-router'
import { Routes } from 'src/routes'

const { Item: MenuItem } = Menu

const StyledMenu = styled(Menu)`
  border: 0;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  background: none;
`
const DropdownMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.black};
`

const StyledDropdown = styled(Dropdown)`
  margin-left: 16px;
  margin-right: 0 !important;
  padding-right: 0 !important;
  opacity: 0;

  svg {
    width: 16px;
    height: 16px;
  }
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.palette.black};
  margin-bottom: 0 !important;
  margin-top: 0 !important;

  svg {
    margin-right: 0;
  }

  span {
    color: ${(props) => props.theme.palette.black};
    font-size: 16px;
    padding-right: 12px;
  }

  ::after {
    border-right: 0 !important;
  }

  :hover ${StyledDropdown} {
    opacity: 1;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 12px;
    cursor: pointer;
    fill: ${(props) => props.theme.palette.blue};
  }
`

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
`

const DashboardTitleWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

type SiderMenuPropsType = {}

const DashboardMenu = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { dashboards, setCurrentIndex, currentIndex } = useDashboard()
  const { addDashboard, updateDashboard, deleteDashboard } = useUpdateDashboard()
  const [editingIndex, setEditingIndex] = useState<number | null>(null)

  const handleCreateDashboard = () => {
    addDashboard()
  }

  const handleClick = (index: number) => {
    setCurrentIndex(index)
    push(formatRoute(Routes.Dashboard, { dashboardId: dashboards[index].id }))
  }

  return (
    <StyledMenu
      selectedKeys={[`dashboard-${currentIndex}`]}
      selectable
      mode="inline"
      key="Menu"
      defaultOpenKeys={['home']}
    >
      <Menu.ItemGroup
        title={
          <TitleWrapper>
            <Title>{t('layout.siderMenu.myDashboard')}</Title>
            <PlusIcon onClick={handleCreateDashboard} />
          </TitleWrapper>
        }
      >
        {R.addIndex<Dashboard>(R.map)(({ title }, index) => {
          return (
            <StyledMenuItem key={`dashboard-${index}`}>
              <DashboardTitleWrapper onClick={() => handleClick(index)}>
                {editingIndex === index ? (
                  <Input
                    defaultValue={title}
                    onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      const updatedTitle = R.compose(
                        (value) => (isNilOrEmpty(value) ? `Dashboard ${index + 1}` : value),
                        R.trim,
                        R.pathOr('', ['target', 'value'])
                      )(e)
                      updateDashboard(index, { title: updatedTitle })
                      setEditingIndex(null)
                    }}
                    autoFocus
                    onBlur={() => setEditingIndex(null)}
                  />
                ) : (
                  <span>{title}</span>
                )}
              </DashboardTitleWrapper>

              <StyledDropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <DropdownMenuItem onClick={() => setEditingIndex(index)}>
                      <EditOutlined />
                      {t('layout.siderMenu.dashbaordList.rename')}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      disabled={dashboards.length < 2}
                      onClick={() => deleteDashboard(index)}
                    >
                      <ClearOutlined />
                      {t('layout.siderMenu.dashbaordList.delete')}
                    </DropdownMenuItem>
                  </Menu>
                }
                placement="bottomLeft"
              >
                <MoreOutlined />
              </StyledDropdown>
            </StyledMenuItem>
          )
        })(dashboards)}
      </Menu.ItemGroup>
    </StyledMenu>
  )
}

export default DashboardMenu
