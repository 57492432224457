import { Layout } from 'antd'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

import Header from './Header'
import SiderMenu from './SiderMenu'

const { Content } = Layout

const StyledLayout = styled(Layout)`
  height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.palette.grey};
  overflow: hidden;
`

// const StyledContent = styled(Content)`
//   overflow: scroll !important;
//   position: relative;
//   background-color: ${(props) => props.theme.palette.grey};
//   width: 100%;
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */

//   ::-webkit-scrollbar {
//     display: none;
//   }
// `

const ModalContainer = styled.div`
  width: 0;
  height: 0;
`

const MainLayout: FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true)
  const handle = useFullScreenHandle()
  return (
    <FullScreen handle={handle}>
      <Layout>
        <SiderMenu collapsed={collapsed} onCollapse={setCollapsed} />
        <StyledLayout>
          <Header fullScreenHandle={handle} onMenuClick={() => setCollapsed(!collapsed)} />
          {children}
        </StyledLayout>
      </Layout>
      <ModalContainer id="ui-container" />
    </FullScreen>
  )
}

export default MainLayout
