import Firebase from 'firebase'
import { useFirebaseAuth } from 'src/hooks/auth'
import React, { FunctionComponent, createContext, useContext } from 'react'

type AuthContextType = {
  user: Firebase.User | null
  isInitialized: boolean
  isLoggedIn: boolean
}

const Context = createContext<AuthContextType>({
  user: null,
  isInitialized: false,
  isLoggedIn: false,
})

const AuthProvider: FunctionComponent = ({ children }) => {
  const { isInitialized, isLoggedIn, currentUser } = useFirebaseAuth()
  return (
    <Context.Provider value={{ user: currentUser, isInitialized, isLoggedIn }}>
      {children}
    </Context.Provider>
  )
}

const useAuth = () => useContext(Context)

export { AuthProvider, useAuth }
