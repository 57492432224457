import React, { FunctionComponent } from 'react'
import { Dropdown as AntdDropdown } from 'antd'
import { DropDownProps } from 'antd/lib/dropdown'

const Dropdown: FunctionComponent<DropDownProps> = ({ children, ...props }) => {
  const container = document.getElementById('ui-container')
  return (
    <AntdDropdown getPopupContainer={container ? () => container : undefined} {...props}>
      {children}
    </AntdDropdown>
  )
}

export default Dropdown
