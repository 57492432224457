import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'
import styled from 'styled-components'

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 120px;
`

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export default () => (
  <SpinnerWrapper>
    <Spin indicator={antIcon} />
  </SpinnerWrapper>
)
