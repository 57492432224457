import RGL from 'react-grid-layout'

export enum WidgetType {
  CRYPTO_GAS_PRICE = 'CRYPTO_GAS_PRICE',
  CRYPTO_GAS_SUMMARY = 'CRYPTO_GAS_SUMMARY',
  HTML = 'HTML',
}

interface BaseWidget {
  id: string
  type: WidgetType
}

export interface HtmlWidgetType extends BaseWidget {
  url?: string
  html?: string
}
export interface GasFeeSummaryWidgetType extends BaseWidget {
  address: string
}

export type Dashboard = {
  id: string
  title: string
  widgets: Record<string, Widget>
  layout: RGL.Layout[]
}

export type Widget = HtmlWidgetType | GasFeeSummaryWidgetType // | RGL.Layout
