import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enUSTranslations from './locales/en-US.json'
import zhHKTranslations from './locales/zh-HK.json'

i18n.use(initReactI18next).init({
  lng: 'en-US',
  resources: {
    'zh-HK': {
      translation: zhHKTranslations,
    },
    'en-US': {
      translation: enUSTranslations,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  debug: false,
})

export default i18n
