import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Dashboard from 'src/components/Dashboard'
import LoadingSpinner from 'src/components/LoadingSpinner'
import { useDashboard } from 'src/context/DashboardContext'

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1200px;
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 200px;
  min-width: 1200px;
`

const DashboardPage: React.FC = () => {
  const { loading, dashboards, setCurrentIndex } = useDashboard()
  const { dashboardId } = useParams<{ dashboardId: string }>()

  useEffect(() => {
    const index = dashboards.findIndex(({ id }) => id === dashboardId)
    if (index !== -1) {
      setCurrentIndex(index)
    }
  }, [dashboardId, dashboards, setCurrentIndex])

  return (
    <>
      {loading ? (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      ) : (
        <Dashboard />
      )}
    </>
  )
}

export default DashboardPage
