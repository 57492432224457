import React, { FunctionComponent, createContext, useContext, useEffect, useState } from 'react'

type WindowContextType = {
  isMouseDown: boolean
}

const Context = createContext<WindowContextType>({
  isMouseDown: false,
})

const WindowProvider: FunctionComponent = ({ children }) => {
  const [isMouseDown, setIsMouseDown] = useState(false)
  useEffect(() => {
    window.addEventListener('mousedown', () => {
      setIsMouseDown(true)
    })
    window.addEventListener('mouseup', () => {
      setIsMouseDown(false)
    })
  }, [])
  return <Context.Provider value={{ isMouseDown }}>{children}</Context.Provider>
}

const useWindow = () => useContext(Context)

export { WindowProvider, useWindow }
