import RGL from 'react-grid-layout'
import { Dashboard, Widget } from 'src/interfaces'
import firebase from './firebase'

const signInFirebaseWithEmail = (email: string, password: string) =>
  firebase.auth().signInWithEmailAndPassword(email, password)

const signOut = () => firebase.auth().signOut()

const getIdToken = () => firebase.auth().currentUser?.getIdToken()

const getUser = () => firebase.auth().currentUser

const updateDashboard = (uid: string, boardIndex: number, updates: Partial<Dashboard>) =>
  firebase.database(`/Users/${uid}/dashboards/${boardIndex}`).ref().update(updates)

const setDashboardWidget = (
  uid: string,
  boardIndex: number,
  widgetId: string,
  updatedWidget: Widget | null
) =>
  firebase
    .database(`/Users/${uid}/dashboards/${boardIndex}/widgets/${widgetId}`)
    .ref()
    .set(updatedWidget)

export {
  signInFirebaseWithEmail,
  signOut,
  getIdToken,
  getUser,
  updateDashboard,
  setDashboardWidget,
}
export default firebase
