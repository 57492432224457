import React from 'react'
import { Redirect, RouteProps } from 'react-router'

import DashboardPage from './pages/DashboardPage'
import RootPage from './pages/RootPage'

enum Routes {
  Dashboard = '/app/board/:dashboardId',
  Root = '/',
}

const publicRoutes: RouteProps[] = [
  {
    path: Routes.Dashboard,
    component: DashboardPage,
    exact: true,
  },
  {
    path: Routes.Root,
    component: RootPage,
    exact: true,
  },
  {
    path: '*',
    render: () => <Redirect to={Routes.Root} />,
  },
]

const restrictedRoutes: RouteProps[] = [
  {
    path: '*',
    render: () => <Redirect to={Routes.Dashboard} />,
  },
]

export { Routes, publicRoutes, restrictedRoutes }
