import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as DazhboardLogo } from 'src/assets/dazhboard-logo.svg'

import styled from 'styled-components'

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  svg {
    width: 24px;
    height: 24px;
  }
`

const LogoBanner = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.span`
  font-size: 22px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.black};
`

const Header = () => {
  return (
    <Link to="/">
      <LogoBanner>
        <LogoWrapper>
          <DazhboardLogo />
        </LogoWrapper>
        <Title>Dazhboard</Title>
      </LogoBanner>
    </Link>
  )
}

export default Header
