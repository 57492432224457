import React, {
  FunctionComponent,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import * as R from 'ramda'
import store from 'store'
import { Dashboard } from 'src/interfaces'
import firebase, { getUser } from 'src/utils/firebase'
import {
  complementDashboards,
  generateId,
  isValidDashboards,
  layoutTransformForLocal,
} from 'src/utils'

type DashboardContextType = {
  loading: boolean
  dashboards: Dashboard[]
  currentIndex: number
  setCurrentIndex: (currentIndex: number) => void
  setDashboards: (dashboards: Dashboard[]) => void
}

const Context = createContext<DashboardContextType>({
  loading: false,
  dashboards: [],
  currentIndex: 0,
  setCurrentIndex: () => null,
  setDashboards: () => null,
})

const DashboardProvider: FunctionComponent = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false)
  // const { isLoggedIn } = useAuth()
  const [dashboards, setDashboards] = useState<Dashboard[]>([
    {
      id: generateId(),
      title: `Dashboard 1`,
      widgets: {},
      layout: [],
    },
  ])
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const uid = R.propOr(null, 'uid')(getUser())
    if (!uid) {
      const storedDashboards = store.get('dashboards')
      if (isValidDashboards(storedDashboards)) {
        setDashboards(complementDashboards(storedDashboards))
      }
      setIsInitialized(true)
    } else {
      const ref = firebase.database().ref(`Users/${uid}`)
      const listener = ref.on('value', (res) => {
        if (res.val()) {
          const { dashboards } = res.val()
          const transformedDashboard = R.map(({ layout, ...dashboard }: Dashboard) => ({
            ...dashboard,
            layout: layoutTransformForLocal(layout || []),
          }))(dashboards)

          setDashboards(complementDashboards(transformedDashboard as Dashboard[]))
        } else {
          const storedDashboards = store.get('dashboards')
          if (isValidDashboards(storedDashboards)) {
            setDashboards(complementDashboards(storedDashboards))
          }
        }
        setIsInitialized(true)
      })
      return () => ref.off('value', listener)
    }
  }, [])

  useEffect(() => {
    if (isInitialized) {
      const updatedAt = new Date().getTime()
      store.set('dashboards', dashboards)
      store.set('updatedAt', updatedAt)
      // syncDashboards(dashboards, updatedAt)
    }
  }, [dashboards, isInitialized])

  const loading = !isInitialized

  const contextValue = useMemo(
    () => ({
      loading,
      dashboards,
      currentIndex,
      setCurrentIndex,
      setDashboards,
    }),
    [currentIndex, dashboards, loading]
  )
  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

const useDashboard = () => useContext(Context)

export { DashboardProvider, useDashboard }
