import { useCallback, useState } from 'react'
import RGL from 'react-grid-layout'
import * as R from 'ramda'
import { useDashboard } from 'src/context/DashboardContext'
import firebase, { getUser } from 'src/utils/firebase'
import { layoutTransformForFirebase } from 'src/utils'

type UseUpdateLayout = {
  isLoading: boolean
  error: Error | null
  updateLayout: (boardIndex: number, layout: RGL.Layout[]) => void
}

const useUpdateLayout = (): UseUpdateLayout => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const { dashboards, setDashboards } = useDashboard()

  // Layout
  const updateLayout = useCallback(
    async (boardIndex: number, layout: RGL.Layout[]) => {
      setError(null)
      setIsLoading(true)
      R.compose(
        setDashboards,
        R.assocPath<RGL.Layout[], any[]>([boardIndex, 'layout'], layout),
        R.clone
      )(dashboards)
      try {
        const uid: string | null = R.propOr(null, 'uid')(getUser())
        if (uid) {
          await firebase
            .database()
            .ref(`/Users/${uid}/dashboards/${boardIndex}/layout`)
            .set(layoutTransformForFirebase(layout))
        }
      } catch (error) {
        console.log(error)
        setError(error)
      }
      setIsLoading(false)
    },
    [dashboards, setDashboards]
  )

  return { isLoading, error, updateLayout }
}

export default useUpdateLayout
