import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import { FullScreenHandle } from 'react-full-screen'

import CreateWidgetModal from 'src/components/CreateWidgetModal'

const CornerWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;

  svg {
    width: 20px;
    height: 20px;
  }
`

type RightCornerProps = {
  fullScreenHandle: FullScreenHandle
}

const RightCorner = ({ fullScreenHandle }: RightCornerProps) => {
  const [isWidgetModalShow, setIsWidgetModalShow] = useState(false)

  const handleCreateWidget = () => {
    setIsWidgetModalShow(true)
  }

  const handleEnterFullScreen = () => {
    fullScreenHandle.enter()
  }
  const handleExitFullScreen = () => {
    fullScreenHandle.exit()
  }
  return (
    <CornerWrapper>
      <IconButton onClick={fullScreenHandle.active ? handleExitFullScreen : handleEnterFullScreen}>
        {fullScreenHandle.active ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
      </IconButton>
      <Button type="primary" onClick={handleCreateWidget}>
        + Widget
      </Button>
      {isWidgetModalShow && (
        <CreateWidgetModal
          visible={isWidgetModalShow}
          onCancel={() => setIsWidgetModalShow(false)}
          onAddCompleted={() => setIsWidgetModalShow(false)}
        />
      )}
    </CornerWrapper>
  )
}

export default RightCorner
