import { useCallback, useState } from 'react'
import * as R from 'ramda'

import { getWalletTransactions, getETHUSDPrice } from 'src/utils/api'

type EtherscanTransaction = {
  blockHash: string
  gas: string
  gasPrice: string
  gasUsed: string
  cumulativeGasUsed: string
  txreceipt_status: '1' | '0'
  nonce: string
  from: string
}

type Summary = {
  totalGasUsed: string
  totalGasPrice: string
  avgGasPrice?: string
  totalGasFee: string
  totalGasFeeInUsd?: string
  totalTrans: string
  successCount: string
  failedCount: string
}

type UseGetGasFeeSummary = [
  {
    summary?: Summary
    loading: boolean
  },
  (address: string) => void
]

export const useGetGasFeeSummary = (): UseGetGasFeeSummary => {
  const [loading, setLoading] = useState(false)
  const [summary, setSummary] = useState<Summary>()
  const [error, setError] = useState<Error>()

  const getGasFeeSummary = useCallback(async (address: string) => {
    setLoading(true)
    try {
      const { data } = await getWalletTransactions(address)
      const {
        data: { USD },
      } = await getETHUSDPrice()
      const result: EtherscanTransaction[] = R.prop('result')(data)
      const { totalGasUsed, totalGasFee, totalGasPrice, totalTrans, failedCount } = R.reduce<
        EtherscanTransaction,
        any
      >(
        (acc, { from, gasUsed, gasPrice, txreceipt_status: status }) =>
          R.equals(R.toLower(from), R.toLower(address))
            ? {
                totalGasUsed: acc.totalGasUsed + Number(gasUsed),
                totalGasPrice: acc.totalGasPrice + Number(gasPrice),
                totalGasFee: acc.totalGasFee + Number(gasUsed) * Number(gasPrice),
                totalTrans: acc.totalTrans + 1,
                failedCount: status === '0' ? acc.failedCount + 1 : acc.failedCount,
              }
            : acc,
        { totalGasUsed: 0, totalGasFee: 0, totalGasPrice: 0, totalTrans: 0, failedCount: 0 }
      )(result)
      setSummary({
        totalGasUsed: (totalGasUsed / 10 ** 6).toFixed(3),
        totalGasFee: (totalGasFee / 10 ** 18).toFixed(3),
        totalGasFeeInUsd: ((totalGasFee * USD) / 10 ** 18).toFixed(2),
        totalGasPrice: (totalGasPrice / 10 ** 9).toFixed(2),
        totalTrans: totalTrans.toString(),
        successCount: (totalTrans - failedCount).toString(),
        failedCount: failedCount.toString(),
        avgGasPrice: (totalGasPrice / totalTrans / 10 ** 9).toFixed(2),
      })
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }, [])

  return [{ summary, loading }, getGasFeeSummary]
}
