import React from 'react'
import * as R from 'ramda'
import RGL, { WidthProvider } from 'react-grid-layout'
import styled from 'styled-components'
// import './styles.css'
import DashboardItem from 'src/components/DashboardItem'
import { useDashboard } from 'src/context/DashboardContext'
import { Widget } from 'src/interfaces'
import useUpdateLayout from 'src/hooks/dashboard/useUpdateLayout'

const ReactGridLayout = WidthProvider(RGL)

const StyledReactGridLayout = styled(ReactGridLayout)`
  width: 100%;

  .react-grid-placeholder {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='3' stroke-dasharray='4%2c 12' stroke-dashoffset='39' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 6px;
  }

  .react-grid-item:not(.react-grid-placeholder) {
    .react-resizable-handle:not(.react-grid-placeholder) {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      right: 0;
      cursor: se-resize;

      :after {
        content: '';
        position: absolute;
        right: 4px;
        bottom: 4px;
        width: 8px;
        height: 8px;
        border-right: 2px solid rgba(0, 0, 0, 0.4);
        border-bottom: 2px solid rgba(0, 0, 0, 0.4);
      }
    }
  }
`

type DashboardProps = {
  widgets: Widget[]
  layout: RGL.Layout[]
  onLayoutChange: (layout: RGL.Layout[]) => void
}

const Dashboard = () => {
  const { dashboards, currentIndex } = useDashboard()
  const { updateLayout } = useUpdateLayout()
  const config = {
    rowHeight: 80,
    cols: 12,
    isResizable: true,
    // verticalCompact: false,
    draggableHandle: '.react-draggable-handle',
  }

  const { widgets, layout } = dashboards[currentIndex]
  return (
    <StyledReactGridLayout
      layout={layout}
      onDragStop={(layout) => updateLayout(currentIndex, layout)}
      onResizeStop={(layout) => updateLayout(currentIndex, layout)}
      margin={[12, 8]}
      {...config}
    >
      {R.map(({ i }: RGL.Layout) => <DashboardItem key={i} widget={widgets[i]} />)(layout)}
    </StyledReactGridLayout>
  )
}

export default Dashboard
