import React from 'react'
import styled from 'styled-components'
import { Widget } from 'src/interfaces'
import WidgetItem from 'src/components/WidgetItem'
import WidgetTools, { WidgetToolsWrapper } from './WidgetTools'

const Wrapper = styled.div`
  box-shadow: 1px 2px 4px rgba(0, 21, 41, 0.08);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  transition: z-index 0.2s ease-in-out;

  :hover {
    z-index: 100;
  }

  :hover ${WidgetToolsWrapper} {
    opacity: 1;
    z-index: 100;
  }
`

const WidgetItemWrapper = styled.div`
  overflow: scroll;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
`

type DashboardItemProps = {
  children?: React.ReactElement
  widget: Widget
}

const DashboardItem = ({ widget, ...props }: DashboardItemProps) => {
  return (
    <Wrapper {...props}>
      <WidgetTools widget={widget} />
      <WidgetItemWrapper>
        <WidgetItem widget={widget} />
      </WidgetItemWrapper>
      {props.children}
    </Wrapper>
  )
}

export default DashboardItem
