import { useCallback, useState } from 'react'
import * as R from 'ramda'
import firebase, { getUser } from 'src/utils/firebase'
import { Dashboard } from 'src/interfaces'
import { useDashboard } from 'src/context/DashboardContext'
import { generateId } from 'src/utils'

type UseUpdateDashboard = {
  isLoading: boolean
  error: Error | null
  addDashboard: () => void
  updateDashboard: (boardIndex: number, updates: Partial<Dashboard>) => void
  deleteDashboard: (boardIndex: number) => void
}

const useUpdateDashboard = (): UseUpdateDashboard => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const { currentIndex, dashboards, setDashboards, setCurrentIndex } = useDashboard()

  const deleteDashboard = useCallback(
    async (boardIndex: number) => {
      if (currentIndex === boardIndex && boardIndex === dashboards.length - 1) {
        setCurrentIndex(dashboards.length - 2)
      }

      const updatedDashboards = R.compose(R.remove<Dashboard>(boardIndex, 1), R.clone)(dashboards)
      setDashboards(updatedDashboards)
      try {
        const uid: string | null = R.propOr(null, 'uid')(getUser())
        if (uid) {
          await firebase.database().ref(`/Users/${uid}/dashboards`).set(updatedDashboards)
        }
      } catch (error) {
        setError(error)
      }
    },
    [currentIndex, dashboards, setCurrentIndex, setDashboards]
  )

  const addDashboard = useCallback(async () => {
    const newDashboard: Dashboard = {
      id: generateId(),
      title: `Dashboard ${dashboards.length + 1}`,
      widgets: {},
      layout: [],
    }
    const updatedDashboards = R.compose(R.append(newDashboard), R.clone)(dashboards)
    setDashboards(updatedDashboards as Dashboard[])

    try {
      const uid: string | null = R.propOr(null, 'uid')(getUser())
      if (uid) {
        await firebase
          .database()
          .ref(`/Users/${uid}/dashboards/${updatedDashboards.length - 1}`)
          .set(newDashboard)
      }
    } catch (error) {
      setError(error)
    }
  }, [dashboards, setDashboards])

  const updateDashboard = useCallback(
    async (boardIndex: number, updates: Partial<Dashboard>) => {
      R.compose(
        setDashboards,
        (dashboards: Dashboard[]) =>
          R.update(boardIndex, R.mergeLeft(updates, dashboards[boardIndex]))(dashboards),
        R.clone
      )(dashboards)
      try {
        const uid: string | null = R.propOr(null, 'uid')(getUser())
        if (uid) {
          await firebase.database().ref(`/Users/${uid}/dashboards/${boardIndex}`).update(updates)
        }
      } catch (error) {
        setError(error)
      }
    },
    [dashboards, setDashboards]
  )

  return { isLoading, error, addDashboard, updateDashboard, deleteDashboard }
}

export default useUpdateDashboard
