import { Layout, Menu, Divider } from 'antd'
import React from 'react'
import { LogoutOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase'
import styled from 'styled-components'

import DashboardMenu from './DashboardMenu'
import { useAuth } from 'src/context/AuthContext'
import { useSignOut } from 'src/hooks/auth'

const { Sider } = Layout
const { Item: MenuItem } = Menu

type OverlayProps = {
  collapsed: boolean
}
const Overlay = styled.div<OverlayProps>`
  display: ${(props) => (props.collapsed ? 'none' : 'block')};
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: ${(props) => props.theme.palette.black}59;
`

const StyledSider = styled(Sider)`
  background-color: ${(props) => props.theme.palette.white};
  transition: ${(props) => props.theme.transitions.basic};
  z-index: 100;
  position: fixed;
  height: 100%;
  overflow: hidden;

  .ant-layout-sider-children {
    width: 240px !important;
  }
`

const StyledMenuItem = styled(Menu.Item)`
  :hover {
    color: ${(props) => props.theme.palette.lightPink};
  }

  :active {
    background: ${(props) => props.theme.palette.lightPink}aa;
  }
`

type SiderMenuPropsType = {
  collapsed: boolean
  onCollapse: (collapsed: boolean) => void
}

const SiderMenu = (props: SiderMenuPropsType) => {
  const { isLoggedIn } = useAuth()
  const [, signOut] = useSignOut()
  const { collapsed, onCollapse } = props
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  }
  return (
    <>
      <StyledSider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth="0"
        breakpoint="sm"
        width={240}
      >
        <DashboardMenu />
        <Divider />
        {!isLoggedIn ? (
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        ) : (
          <Menu selectedKeys={[]}>
            <StyledMenuItem onClick={signOut}>
              <LogoutOutlined />
              Logout
            </StyledMenuItem>
          </Menu>
        )}
      </StyledSider>
      <Overlay collapsed={collapsed} onClick={() => onCollapse(true)} />
    </>
  )
}

export default SiderMenu
