import React, { useState } from 'react'
import { Form, Input, Radio } from 'antd'
import styled from 'styled-components'

import { requiredRule } from 'src/constants'

const { Item: FormItem } = Form
const { TextArea } = Input
const { Group } = Radio

const RadioGroup = styled(Group)`
  .ant-radio-wrapper {
    :last-child {
      margin-right: 0;
    }
  }
`

export enum InputMode {
  URL = 'URL',
  HTML = 'HTML',
}

type HtmlWidgetFormProps = {
  initialMode?: InputMode
}

const HtmlWidgetForm = ({ initialMode }: HtmlWidgetFormProps) => {
  const [mode, setMode] = useState(initialMode || InputMode.URL)
  return (
    <>
      <FormItem
        label={
          <RadioGroup value={mode} onChange={(e) => setMode(e.target.value)}>
            <Radio value={InputMode.URL}>URL</Radio>
            <Radio value={InputMode.HTML}>HTML</Radio>
          </RadioGroup>
        }
        name={mode === InputMode.URL ? 'url' : 'html'}
        rules={[requiredRule]}
      >
        {mode === InputMode.URL ? <Input /> : <TextArea rows={10} />}
      </FormItem>
    </>
  )
}

export default HtmlWidgetForm
