import React, { FunctionComponent } from 'react'

import { AuthProvider } from './AuthContext'
import { DashboardProvider } from './DashboardContext'
import { WindowProvider } from './WindowContext'

export const ContextProvider: FunctionComponent = ({ children }) => (
  <AuthProvider>
    <DashboardProvider>
      <WindowProvider>{children}</WindowProvider>
    </DashboardProvider>
  </AuthProvider>
)
