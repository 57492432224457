import React, { useState } from 'react'
import * as R from 'ramda'
import { LeftOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import { useDebounce } from 'use-debounce'
import { v4 } from 'uuid'

import Modal from 'src/components/UI/Modal'
import { ReactComponent as HTMLIcon } from 'src/assets/html-icon.svg'
import { ReactComponent as EtherscanIcon } from 'src/assets/etherscan-icon.svg'

import { WidgetType, Widget } from 'src/interfaces'
import { useDashboard } from 'src/context/DashboardContext'
import WidgetForm from 'src/components/WidgetForm'
import WidgetItem from 'src/components/WidgetItem'

import {
  WidgetsWrapper,
  WidgetsRow,
  StyledCol,
  Title,
  WidgetFormWrapper,
  WidgetFormCol,
  BackWrapper,
  Item,
} from './styles'
import useUpdateWidget from 'src/hooks/dashboard/useUpdateWidget'

enum MenuCategory {
  General = 'general',
  Crypto = 'crypto',
}

type CreateWidgetModalProps = {
  visible: boolean
  onCancel: () => void
  onAddCompleted: () => void
}

const CreateWidgetModal = ({ visible, onCancel, onAddCompleted }: CreateWidgetModalProps) => {
  const [selectedWidgetType, setSelectedWidgetType] = useState<WidgetType | null>(null)
  const [menuCategory, setMenuCategory] = useState(MenuCategory.General)
  const [previewWidget, setPreviewWidget] = useState<Widget>()
  const [debouncedPreviewWidget] = useDebounce(previewWidget, 500)
  const { currentIndex } = useDashboard()
  const { addWidget } = useUpdateWidget()
  const choicesList: Record<string, any[]> = {
    [MenuCategory.General]: [
      {
        icon: <HTMLIcon />,
        title: 'Custom Embed',
        description: 'Embed via URL or HTML',
        type: WidgetType.HTML,
      },
    ],
    [MenuCategory.Crypto]: [
      {
        icon: <EtherscanIcon />,
        title: 'Gas fee summary',
        description: 'Gas fee paid for last 10k transactions',
        type: WidgetType.CRYPTO_GAS_SUMMARY,
      },
      {
        icon: <EtherscanIcon />,
        title: 'Gas tracker',
        description: 'Ethereum gas price tracker',
        type: WidgetType.CRYPTO_GAS_PRICE,
      },
    ],
  }

  const handleBack = () => {
    setSelectedWidgetType(null)
    setPreviewWidget(undefined)
  }

  const handleSubmit = (widget: Widget) => {
    if (selectedWidgetType) {
      addWidget(currentIndex, widget)
      onAddCompleted()
    }
  }
  return (
    <Modal
      bodyStyle={{ height: 600, display: 'flex', flexDirection: 'column' }}
      width={800}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {!selectedWidgetType && (
        <>
          <Title>Add widget</Title>
          <WidgetsWrapper>
            <Menu
              style={{ width: 120 }}
              selectedKeys={[menuCategory]}
              onSelect={({ key }) => setMenuCategory(key as MenuCategory)}
            >
              <Menu.Item key={MenuCategory.General}>General</Menu.Item>
              <Menu.Item key={MenuCategory.Crypto}>Crypto</Menu.Item>
            </Menu>
            <WidgetsRow gutter={[16, 16]}>
              {R.map(({ icon, title, description, type }) => (
                <StyledCol key={v4()} span={8}>
                  <Item onClick={() => setSelectedWidgetType(type)}>
                    {icon}
                    <h3>{title}</h3>
                    <div>{description}</div>
                  </Item>
                </StyledCol>
              ))(choicesList[menuCategory] || [])}
            </WidgetsRow>
          </WidgetsWrapper>
        </>
      )}
      {selectedWidgetType && (
        <>
          <BackWrapper onClick={handleBack}>
            <LeftOutlined /> Back
          </BackWrapper>
          <WidgetFormWrapper>
            <WidgetFormCol>
              <WidgetForm
                type={selectedWidgetType}
                onValuesChange={setPreviewWidget}
                onSubmit={handleSubmit}
              />
            </WidgetFormCol>
            <WidgetFormCol>
              <WidgetItem widget={debouncedPreviewWidget} />
            </WidgetFormCol>
          </WidgetFormWrapper>
        </>
      )}
    </Modal>
  )
}

export default CreateWidgetModal
