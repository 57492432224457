import { useCallback, useState } from 'react'
// import { useFetchUserDetail } from '../api/user'
import { signOut as signOutFirebase } from 'src/utils/firebase'

type UseSignOut = [{ loading: boolean }, () => void]

export const useSignOut = (): UseSignOut => {
  const [loading, setLoading] = useState(false)

  const signOut = useCallback(async () => {
    setLoading(true)
    await signOutFirebase()
    setLoading(false)
  }, [])

  return [{ loading }, signOut]
}
