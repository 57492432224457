import React, { useEffect, useCallback } from 'react'
import { Form, Button } from 'antd'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import styled from 'styled-components'
import { WidgetType, Widget, HtmlWidgetType, GasFeeSummaryWidgetType } from 'src/interfaces'
import HtmlWidgetForm, { InputMode } from './HtmlWidgetForm'
import GasFeeSummaryWidgetForm from './GasFeeSummaryWidgetForm'

const { useForm, Item: FormItem } = Form

const Wrapper = styled.div`
  margin-top: 12px;
`

const ButtonWrapper = styled(FormItem)`
  margin-bottom: 0;
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }
`

type WidgetFormProps = {
  type: WidgetType
  widget?: Widget
  onSubmit: (widget: Widget) => void
  onValuesChange?: (widget: Widget) => void
}

const WidgetForm = ({ type, onSubmit, widget, onValuesChange }: WidgetFormProps) => {
  const [form] = useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (widget) {
      switch (type) {
        case WidgetType.HTML:
          form.setFieldsValue({
            url: R.prop('url', widget as HtmlWidgetType),
            html: R.prop('html', widget as HtmlWidgetType),
          })

          break
        case WidgetType.CRYPTO_GAS_SUMMARY:
          form.setFieldsValue({
            address: R.prop('address', widget as GasFeeSummaryWidgetType),
          })

          break

        default:
          break
      }
    }
  }, [widget, form, type])

  const handleValuesChange = useCallback(() => {
    if (onValuesChange) {
      const values = form.getFieldsValue()
      const updatedWidget = {
        ...values,
        type,
        id: widget ? R.prop('id')(widget) : v4(),
      }
      onValuesChange(updatedWidget)
    }
  }, [form, onValuesChange, type, widget])

  const handleFinish = useCallback(
    (form: any) => {
      const updatedWidget = {
        ...form,
        type,
        id: widget ? R.prop('id')(widget) : v4(),
      }
      onSubmit(updatedWidget)
    },
    [onSubmit, type, widget]
  )

  return (
    <Wrapper>
      <Form
        form={form}
        layout="vertical"
        colon
        onValuesChange={handleValuesChange}
        onFinish={handleFinish}
      >
        {type === WidgetType.HTML && (
          <HtmlWidgetForm
            initialMode={R.propOr(null, 'html')(widget) ? InputMode.HTML : InputMode.URL}
          />
        )}
        {type === WidgetType.CRYPTO_GAS_SUMMARY && <GasFeeSummaryWidgetForm />}
        <ButtonWrapper>
          <Button type="primary" htmlType="submit">
            {widget ? t('component.widgetForm.updateWidget') : t('component.widgetForm.addWidget')}
          </Button>
        </ButtonWrapper>
      </Form>
    </Wrapper>
  )
}

export default WidgetForm
