import { useEffect, useState } from 'react'
import Firebase from 'firebase'
import firebase from 'src/utils/firebase'

const useFirebaseAuth = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [currentUser, setCurrentUser] = useState<Firebase.User | null>(null)

  useEffect(() => {
    const unlisten = firebase.auth().onAuthStateChanged(async (authUser) => {
      setIsLoggedIn(Boolean(authUser))
      setIsInitialized(true)
      setCurrentUser(authUser)
    })
    return () => {
      unlisten()
    }
  }, [])

  return { isInitialized, isLoggedIn, currentUser }
}

export { useFirebaseAuth }
