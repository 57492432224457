import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { Widget, GasFeeSummaryWidgetType } from 'src/interfaces'
import { useGetGasFeeSummary } from 'src/hooks/widgets'
import RefreshButton from 'src/components/UI/RefreshButton'
import ethLogo from 'src/assets/eth-logo.png'

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SummaryWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  min-width: 260px;
  height: 100%;
  padding: 12px;
  background: ${(props) =>
    `linear-gradient(135deg, ${props.theme.palette.lightBlue} 0%, ${props.theme.palette.blue} 100%)`};
  color: ${(props) => props.theme.palette.white};
`

const Title = styled.h3`
  color: ${(props) => props.theme.palette.white};
  margin: 12px 0 0;
  font-size: 14px;
`

const Address = styled.div`
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.palette.white};
  border-radius: 24px;
  padding: 4px 8px;
  width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  align-items: flex-end;
`

const Value = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
`

const SubVlaue = styled.div`
  margin-left: 4px;
  margin-bottom: 4px;
  padding-right: 8px;
  font-size: 14px;
`

const EthIcon = styled.img`
  width: 18px;
  margin-right: 6px;
`

type GasFeeSummaryWidgetProps = {
  widget: Widget
}

const GasFeeSummaryWidget = ({ widget }: GasFeeSummaryWidgetProps) => {
  const { t } = useTranslation()
  const [{ loading, summary }, getGasFeeSummary] = useGetGasFeeSummary()
  const { address } = widget as GasFeeSummaryWidgetType

  useEffect(() => {
    getGasFeeSummary(address)
  }, [address, getGasFeeSummary])

  const handleRefresh = useCallback(() => {
    getGasFeeSummary(address)
  }, [address, getGasFeeSummary])

  const loadingPlaceholder = 'Loading...'
  return (
    <SummaryWrapper>
      <HeaderWrapper>
        <Tooltip overlayStyle={{ fontSize: 12 }} placement="bottom" title={address}>
          <Address>{address}</Address>
        </Tooltip>
        <RefreshButton isLoading={!summary || loading} onRefresh={handleRefresh} />
      </HeaderWrapper>
      <Title>{t('component.widget.gasFeeSummary.totalGasSpent')}</Title>
      <Content>
        {!summary ? (
          loadingPlaceholder
        ) : (
          <>
            <Value>
              <EthIcon src={ethLogo} alt="eth-icon" />
              {R.propOr('Loading...', 'totalGasFee')(summary)}
            </Value>
            <SubVlaue>{`≈ ${R.propOr('', 'totalGasFeeInUsd')(summary)} USD`}</SubVlaue>
          </>
        )}
      </Content>
      <Title>{t('component.widget.gasFeeSummary.gasUsed')}</Title>
      <Content>
        {!summary ? (
          loadingPlaceholder
        ) : (
          <>
            <Value>{`${R.propOr('Loading...', 'totalGasUsed')(summary)} M`}</Value>
            <SubVlaue>{`(avg. ${R.propOr('', 'avgGasPrice')(summary)} gwei)`}</SubVlaue>
          </>
        )}
      </Content>
    </SummaryWrapper>
  )
}

export default GasFeeSummaryWidget
