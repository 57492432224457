import React, { FunctionComponent } from 'react'
import { Modal as AntdModal } from 'antd'
import { ModalProps } from 'antd/lib/modal'

const Modal: FunctionComponent<ModalProps> = ({ children, ...props }) => (
  <AntdModal getContainer={document.getElementById('ui-container')} {...props}>
    {children}
  </AntdModal>
)

export default Modal
