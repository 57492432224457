import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDashboard } from 'src/context/DashboardContext'
import { Routes } from 'src/routes'
import { formatRoute } from 'src/utils'

const RootPage: React.FC = () => {
  const { loading, dashboards } = useDashboard()
  const { push } = useHistory()

  useEffect(() => {
    if (!loading && dashboards[0].id) {
      push(formatRoute(Routes.Dashboard, { dashboardId: dashboards[0].id }))
    }
  }, [dashboards, loading, push])

  return null
}

export default RootPage
