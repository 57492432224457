import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input } from 'antd'

import { requiredRule } from 'src/constants'

const { Item: FormItem } = Form

const GasFeeSummaryWidgetForm = () => {
  const { t } = useTranslation()
  return (
    <FormItem
      label={t('component.widget.gasFeeSummary.address')}
      name="address"
      rules={[requiredRule]}
    >
      <Input />
    </FormItem>
  )
}

export default GasFeeSummaryWidgetForm
